import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Issue_list from "../components/Issue/Issue_list";
const Issue = () => {
  return (
    <div>
      <Issue_list />
    </div>
  );
};

export default Issue;
