import React from "react";
import Faq_list from "../components/Faq/Faq_list";

const Faq = () => {
  return (
    <div>
      <Faq_list />
    </div>
  );
};

export default Faq;
