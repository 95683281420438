import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Vendor_product_list from "../components/Product/Vendor_product_list";
const Vendor_product = () => {
  return (
    <div>
      <Vendor_product_list />
    </div>
  );
};

export default Vendor_product;
