import React, { useContext } from "react";
import Homes from "../components/Homes/Homes";
import { AuthContext } from "../contexts/UserProvider";
import SchoolDataList from "../components/School/SchoolDataList";

const Home = () => {
  const { user } = useContext(AuthContext);
  console.log(user);

  return (
    <div>{user?.role === "schoolManager" ? <SchoolDataList /> : <Homes />}</div>
  );
};

export default Home;
