import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ContactList from "../components/Orders/Contact_list";
const Contact = () => {
  return (
    <div>
      <ContactList />
    </div>
  );
};

export default Contact;
