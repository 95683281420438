import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import img3 from "../../img/college/Icon feather-edit.png";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import "./School.css";
import CustomerDemoStatusUpdate from "../../pages/edit/CustomerDemoStatusUpdate";

const StudentsList = () => {
  const { id } = useParams();
  const { data, error, refetch } = useQuery(
    "customerDemoListData",
    async () => {
      const response = await axios.get(
        `https://api.dsmartuniforms.com/api/school/${id}`,
        {
          mode: "cors",
        }
      );
      return response.data;
    }
  );

  useEffect(() => {
    if (error) {
      console.error("Error fetching banner data:", error);
    }
  }, [error, data]);
  const mainData = data?.students?.map((item) => item);
  const MySwal = withReactContent(Swal);
  const columns = [
    {
      text: "No",
      formatter: (cellContent, row, index) => {
        return (
          <>
            {" "}
            <p>{index + 1}</p>
          </>
        );
      },
    },

    {
      dataField: "name",
      text: "Studnet Name",
    },

    {
      dataField: "class",
      text: "Class",
    },
    {
      dataField: "mobile",
      text: "Gardian Mobile",
    },
    {
      dataField: "total",
      text: "Total Set",
    },
    {
      dataField: "height",
      text: "Height",
    },
    {
      dataField: "category",
      text: "Category",
    },
    {
      dataField: "chest",
      text: "Chest",
    },
    {
      dataField: "chestSize",
      text: "Chest Size",
    },
    {
      dataField: "waist",
      text: "Waist",
    },
    {
      dataField: "waistSize",
      text: "Waist Size",
    },
    {
      dataField: "shoulder",
      text: "Shoulder",
    },
    {
      dataField: "sleeveLength",
      text: "Sleeve Length",
    },
    {
      dataField: "collar",
      text: "Collar",
    },
    {
      dataField: "length",
      text: "Length",
    },
    {
      dataField: "armhole",
      text: "Arm Hole",
    },
    {
      dataField: "sleeveOpening",
      text: "Sleeve Opening",
    },

    {
      dataField: "bottomHem",
      text: "Bottom Hem",
    },
    {
      dataField: "halfBody",
      text: "Half Body",
    },
    {
      dataField: "hips",
      text: "Hips",
    },

    // {
    //   text: "Demo Status Update",
    //   formatter: (cellContent, row) => {
    //     return (
    //       <>
    //         {" "}
    //         <div className="d-flex justify-content-center">
    //           <img
    //             src={img3}
    //             alt=""
    //             data-toggle="modal"
    //             data-target={`#loginModal2${row._id}`}
    //           />
    //         </div>
    //         <div
    //           className="modal fade"
    //           id={`loginModal2${row._id}`}
    //           tabIndex="{-1}"
    //           role="dialog"
    //           aria-labelledby="loginModal"
    //           aria-hidden="true"
    //         >
    //           <div className="modal-dialog modal-dialog-centered modal-lg">
    //             <div className="modal-content">
    //               <div className="modal-body">
    //                 <CustomerDemoStatusUpdate data={row} refetch={refetch} />
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    style: { width: 60 },
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  //delete
  const [products, setProducts] = useState(data);
  const handleCategory = async (id) => {
    const confirmation = window.confirm("Are you Sure?");
    if (confirmation) {
      const url = `https://api.dsmartuniforms.com/api/banner/${id}`;
      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          MySwal.fire("Good job!", "successfully deleted", "success");
          if (data.deletedCount === 1) {
            const remainItem = products.filter((item) => item._id !== id);
            setProducts(remainItem);
          }
        });
    }
  };
  return (
    <div className="wrapper">
      <div className="content-wrapper" style={{ background: "unset" }}>
        <section className="content customize_list">
          <div className="school">
            <div>
              <h1 className="text-center">{data?.name}</h1>
              <div className="school-pic">
                <img
                  src={data?.photos && data?.photos}
                  alt=""
                  style={{ width: 420 }}
                />
                <img
                  src={
                    "https://i.ibb.co/FxNyg4P/Screenshot-2024-03-19-at-3-52-34-AM.png"
                  }
                  alt=""
                  style={{ width: "75%" }}
                />
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="card">
              <div className="card-body card_body_sm">
                <>
                  <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    columns={columns}
                    data={mainData || []}
                    pagination={pagination}
                    exportCSV
                  >
                    {(props) => (
                      <React.Fragment>
                        <BootstrapTable
                          bootstrap4
                          keyField="_id"
                          columns={columns}
                          data={mainData || []}
                          pagination={pagination}
                          {...props.baseProps}
                        />
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default StudentsList;
