import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import School_list from "../components/School/School_list";
const School = () => {
  return (
    <div>
      <School_list />
    </div>
  );
};

export default School;
