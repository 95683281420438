import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import "./Main_steam.css";
import axios from "axios";
import img from "../../img/college/Icon material-delete.png";

const ProductsUpdate = ({ data, refetch }) => {
  const {
    _id,
    name,
    status,
    price,
    discountPrice,
    photos,
    flashsale,
    sizes: initialSizes,
    colors: initialColors,
  } = data;
  console.log("data", data);
  const [user, setUser] = useState(data);
  const [files, setFiles] = useState([]);
  const [previewPhotos, setPreviewPhotos] = useState([]);
  const [selectedDeletedPhotos, setSelectedDeletedPhotos] = useState([]);
  const [sizes, setSizes] = useState(initialSizes || []);
  const [colors, setColors] = useState(initialColors || []);

  useEffect(() => {
    setPreviewPhotos(photos || []);
  }, [photos]);

  const MySwal = withReactContent(Swal);

  const handleOnBlur = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newInfo = { ...user };
    newInfo[field] = value;
    setUser(newInfo);
  };

  const handleDeleteCategoryPhoto = (photoIndex) => {
    setPreviewPhotos((prevPreviewPhotos) =>
      prevPreviewPhotos.filter((_, index) => index !== photoIndex)
    );

    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== photoIndex)
    );
    setSelectedDeletedPhotos((prevDeletedPhotos) =>
      prevDeletedPhotos.concat(photoIndex)
    );
  };
  const handleSizeChange = (index, event) => {
    const { value } = event.target;
    const newSizes = [...sizes];
    newSizes[index] = value;
    setSizes(newSizes);
  };
  const handleAddSize = () => {
    setSizes([...sizes, ""]);
  };

  const handleRemoveSize = (index) => {
    const newSizes = [...sizes];
    newSizes.splice(index, 1);
    setSizes(newSizes);
  };

  //colors

  const handleSizeChangeColor = (index, event) => {
    const { value } = event.target;
    const newSizes = [...colors];
    newSizes[index] = value;
    setColors(newSizes);
  };
  const handleAddSizeColor = () => {
    setColors([...colors, ""]);
  };

  const handleRemoveSizeColor = (index) => {
    const newSizes = [...colors];
    newSizes.splice(index, 1);
    setColors(newSizes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newPost = {
      ...user,
      sizes,
      colors,
    };

    try {
      const list = await Promise.all(
        Object.values(files).map(async (file) => {
          const data = new FormData();
          data.append("file", file);
          data.append("upload_preset", "upload");
          const uploadRes = await axios.post(
            "https://api.cloudinary.com/v1_1/dtpvtjiry/image/upload",
            data
          );

          const { secure_url } = uploadRes.data;
          return secure_url;
        })
      );

      // Get the indices of photos to be deleted
      const indicesToDelete = selectedDeletedPhotos;

      // Remove the photos at the specified indices from the original photos array
      const updatedPhotos = photos.filter(
        (_, index) => !indicesToDelete.includes(index)
      );

      // Concatenate the new photos from the upload with the remaining photos
      const finalPhotos = updatedPhotos.concat(list);

      const product = {
        ...newPost,
        photos: finalPhotos,
      };

      await axios.patch(
        `https://api.dsmartuniforms.com/api/product/${_id}`,
        product
      );
      MySwal.fire("Good job!", "successfully edited", "success");
      refetch();
    } catch (err) {
      MySwal.fire("Something Error Found.", "warning");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div>
            <div className="card-body">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3"
                >
                  Name
                </label>
                <input
                  type="text"
                  className="main_form  w-100"
                  name="name"
                  onBlur={handleOnBlur}
                  defaultValue={name || ""}
                />
              </div>

              <div className="col-md-12 mb-3">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3"
                >
                  Price
                </label>
                <input
                  type="text"
                  className="main_form  w-100"
                  name="price"
                  onBlur={handleOnBlur}
                  defaultValue={price || ""}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3"
                >
                  Discount Price
                </label>
                <input
                  type="text"
                  className="main_form  w-100"
                  name="discountPrice"
                  onBlur={handleOnBlur}
                  defaultValue={discountPrice || ""}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="inputState" className="">
                  Status ({status})
                </label>
                <br />
                <select
                  name="status"
                  id="inputState"
                  className="main_form"
                  style={{ width: "450px" }}
                  onBlur={handleOnBlur}
                  defaultValue={status || ""}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">InActive</option>
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="inputState" className="">
                  Flash Sale
                </label>
                <br />
                <select
                  name="flashsale"
                  id="inputState"
                  className="main_form"
                  style={{ width: "450px" }}
                  onBlur={handleOnBlur}
                  defaultValue={flashsale || ""}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="col-md-12 mb-3">
                {/* Other input fields */}
                <h2>Sizes</h2>
                {sizes.map((size, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-4 mb-3">
                      <label>Size {index + 1}</label>
                      <input
                        type="text"
                        name="size"
                        value={size}
                        onChange={(e) => handleSizeChange(index, e)}
                        className="form-control"
                      />
                    </div>

                    <div className="col-md-4 d-flex align-items-end">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleRemoveSize(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddSize}
                >
                  Add Size
                </button>
              </div>
              <div className="col-md-12 mb-3">
                {/* Other input fields */}
                <h2>Colors</h2>
                {colors.map((color, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-4 mb-3">
                      <label>Color {index + 1}</label>
                      <input
                        type="text"
                        name="color"
                        value={color}
                        onChange={(e) => handleSizeChangeColor(index, e)}
                        className="form-control"
                      />
                    </div>

                    <div className="col-md-4 d-flex align-items-end">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleRemoveSizeColor(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddSizeColor}
                >
                  Add Size
                </button>
              </div>
              {/* Other input fields */}
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3"
                >
                  Product Images
                </label>
                <input
                  type="file"
                  className="main_form w-100 p-0"
                  name="img"
                  onChange={(e) => {
                    setFiles(e.target.files);
                    // Set preview for new photos
                    const newPreviewPhotos = Array.from(e.target.files).map(
                      (file) => URL.createObjectURL(file)
                    );
                    setPreviewPhotos(newPreviewPhotos);
                  }}
                  multiple
                />

                {previewPhotos.length > 0 && (
                  <div className="mt-2">
                    <p>Preview Photos:</p>
                    {previewPhotos.map((preview, index) => (
                      <div key={index} className="d-flex align-items-center">
                        <img
                          src={preview}
                          alt={`Preview ${index}`}
                          style={{
                            maxWidth: "100px",
                            maxHeight: "100px",
                            marginRight: "5px",
                            marginBottom: "15px",
                          }}
                        />
                        <img
                          src={img}
                          alt="remove category"
                          className="ms-3"
                          onClick={() => handleDeleteCategoryPhoto(index)}
                          style={{ width: 20, cursor: "pointer" }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="profile_btn"
                  style={{ width: 220 }}
                >
                  Edit ProductsUpdate
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProductsUpdate;
