import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Product_list from "../components/Product/Product_list";
const Product = () => {
  return (
    <div>
      <Product_list />
    </div>
  );
};

export default Product;
