import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Branch_list from "../components/Branch/Branch_list";
const Branch = () => {
  return (
    <div>
      <Branch_list />
    </div>
  );
};

export default Branch;
