import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

const CustomerDemoStatusUpdate = ({ data, refetch }) => {
  const { _id, status } = data;
  const [user, setUser] = useState(data);
  const handleOnBlur = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newInfo = { ...user };
    newInfo[field] = value;
    setUser(newInfo);
  };

  const MySwal = withReactContent(Swal);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPost = {
      ...user,
    };
    try {
      await axios.put(
        `https://api.dsmartuniforms.com/api/demo/${_id}`,
        newPost
      );
      MySwal.fire("Updated", "success");
      refetch();
    } catch (err) {
      console.error(err);
      MySwal.fire("Something went wrong.", "error");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div>
          <div className="card-body">
            <div className="col-md-12 mb-3">
              <label htmlFor="inputState" className="form-label profile_label3">
                Status
              </label>
              <select
                name="status"
                id="inputState"
                className="main_form"
                style={{ width: "450px" }}
                onBlur={handleOnBlur}
                defaultValue={status || ""}
              >
                <option value="Pending">Pending</option>
                <option value="Approved">Accept</option>
                <option value="Rejected">Reject</option>
              </select>
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="profile_btn"
                style={{ width: 220 }}
              >
                Edit Issue
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CustomerDemoStatusUpdate;
