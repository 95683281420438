import React, { useContext, useEffect, useState } from "react";
import img from "../../img/college/Icon material-delete.png";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { AiOutlineEye } from "react-icons/ai";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";

import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useQuery } from "react-query";
import { AuthContext } from "../../contexts/UserProvider";

const SchoolDataList = () => {
  const { user } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);
  const { data, error, refetch } = useQuery("schoolData", async () => {
    const response = await axios.get(
      `https://api.dsmartuniforms.com/api/school/schools?email=${user?.email}`,
      {
        mode: "cors",
      }
    );
    return response?.data;
  });
  const status = data ? data.map((item) => item?.status) : [];
  const studentsData = data ? data.map((item) => item?.students) : [];
  const studentsDataLength = studentsData[0]?.length;

  useEffect(() => {
    if (error) {
      console.error("Error fetching school data:", error);
    }
  }, [error, data]);

  //sub stream
  const columns = [
    {
      text: "No",
      formatter: (cellContent, row, index) => {
        return (
          <>
            {" "}
            <p>{index + 1}</p>
          </>
        );
      },
    },
    {
      text: "Image",
      formatter: (cellContent, row) => {
        return (
          <div>
            <img
              src={row?.photos && row?.photos}
              alt=""
              style={{ width: 120 }}
            />
          </div>
        );
      },
    },
    {
      dataField: "_id",
      text: "Id",
    },

    {
      dataField: "name",
      text: "Category",
    },
    {
      dataField: "location",
      text: "Location",
    },
    {
      text: "Link",
      formatter: (cellContent, row) => {
        return (
          <>
            {" "}
            <div className="d-flex justify-content-center">
              {status == "pending" ? (
                <Link
                  to={`https://www.dsmartuniforms.com/schoolDetails/${row._id}`}
                >
                  Share Link
                </Link>
              ) : (
                <p>Already Processing</p>
              )}
            </div>
          </>
        );
      },
    },
    {
      text: "Students Measurement List",
      formatter: (cellContent, row) => {
        return (
          <>
            {" "}
            <div className="d-flex justify-content-center">
              <Link to={`/student_list/${row._id}`}>
                <AiOutlineEye
                  data-toggle="modal"
                  data-target={`#loginModal${row._id}`}
                  style={{ width: "24px", height: "24px" }}
                />
              </Link>
            </div>
          </>
        );
      },
    },

    // {
    //   text: "Action",
    //   formatter: (cellContent, row) => {
    //     return (
    //       <>
    //         {" "}
    //         <div className="d-flex justify-content-center">
    //           <img
    //             src={img3}
    //             alt=""
    //             data-toggle="modal"
    //             data-target={`#loginModal${row._id}`}
    //           />
    //           <img
    //             src={img}
    //             alt=""
    //             className="ms-3"
    //             onClick={() => handleCategory(row._id)}
    //           />
    //         </div>
    //         <div
    //           className="modal fade"
    //           id={`loginModal${row._id}`}
    //           tabIndex="{-1}"
    //           role="dialog"
    //           aria-labelledby="loginModal"
    //           aria-hidden="true"
    //         >
    //           <div className="modal-dialog modal-dialog-centered">
    //             <div className="modal-content" style={{ width: 700 }}>
    //               <div className="modal-body">
    //                 {/* <Category data={row} refetch={refetch} /> */}
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    style: { width: 60 },
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  //delete
  const [products, setProducts] = useState(data);
  const handleCategory = async (id) => {
    const confirmation = window.confirm("Are you Sure?");
    if (confirmation) {
      const url = `https://api.dsmartuniforms.com/api/school/${id}`;
      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          MySwal.fire("Good job!", "successfully deleted", "success");
          if (data.deletedCount === 1) {
            const remainItem = products.filter((item) => item._id !== id);
            setProducts(remainItem);
          }
          refetch();
        });
    }
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper" style={{ background: "unset" }}>
        <section className="content customize_list">
          <div className="container-fluid">
            <div className="row">
              <div className="school">
                <div>
                  {data?.map((item) => {
                    return (
                      <>
                        <h1>{item?.name}</h1>
                        <div className="school">
                          <img
                            src={item?.photos && item?.photos}
                            alt=""
                            style={{ width: 420 }}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="col-md-6 home_card_m">
                <div className="card_1">
                  <div className="d-flex p-3">
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={img} alt="" className="img1" />
                    </div>
                    <div className="ms-3 text-white">
                      <p className="fs-5">Total Bookings</p>
                      <p className="fw-bold">{studentsDataLength} Booking</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card_3">
                  <div className="d-flex p-3">
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={img} alt="" className="img3" />
                    </div>
                    <div className="ms-3 text-white">
                      <p>Order Status</p>
                      <p className="fw-bold">
                        {status == "delivery" ? "Delivered" : status}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ height: "1px", background: "rgb(191 173 173)" }} />
            <div className="card">
              <div className="card-body card_body_sm">
                <>
                  <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    columns={columns}
                    data={data || []}
                    pagination={pagination}
                    exportCSV
                  >
                    {(props) => (
                      <React.Fragment>
                        <BootstrapTable
                          bootstrap4
                          keyField="_id"
                          columns={columns}
                          data={data || []}
                          pagination={pagination}
                          {...props.baseProps}
                        />
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </>
              </div>
            </div>
            {/* /.row (main row) */}
          </div>
          {/* /.container-fluid */}
        </section>
      </div>
    </div>
  );
};

export default SchoolDataList;
