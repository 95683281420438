import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import img3 from "../../img/college/Icon feather-edit.png";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import DemoStatusUpdate from "../../pages/edit/DemoStatusUpdate";
import { AuthContext } from "../../contexts/UserProvider";

const CustomVendorDemoList = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);

  const userId = user?.email;
  const { data, error, refetch } = useQuery(
    "customerVendorDemoData",
    async () => {
      const response = await axios.get(
        `https://api.dsmartuniforms.com/api/adminOrder/single/${id}`,
        {
          mode: "cors",
        }
      );
      return response.data;
    }
  );

  useEffect(() => {
    if (error) {
      console.error("Error fetching banner data:", error);
    }
  }, [error, data]);
  const allData = data?.vendorDemo?.map((item) => item);
  const mainData = allData?.filter((item) => item?.email === user?.email);
  console.log("mainData", data);
  const MySwal = withReactContent(Swal);
  const columns = [
    {
      text: "No",
      formatter: (cellContent, row, index) => {
        return (
          <>
            {" "}
            <p>{index + 1}</p>
          </>
        );
      },
    },

    {
      dataField: "userName",
      text: "Name",
    },
    {
      dataField: "details",
      text: "File Information",
      formatter: (cellContent, row) => {
        const fileDetails = row?.photos?.map((detail) => (
          <div key={detail?._id}>
            <a
              href={`https://api.dsmartuniforms.com/public/uploads/${detail?.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {detail?.url}
            </a>
          </div>
        ));
        return <>{fileDetails}</>;
      },
    },

    {
      text: "Status",
      formatter: (cellContent, row, index) => {
        return (
          <>
            {row?.status === "Pending"}
            <p
              className={
                row?.status === "Approved"
                  ? "badge badge-success"
                  : "badge badge-danger"
              }
              style={{ textTransform: "capitalize" }}
            >
              {row.status}
            </p>
          </>
        );
      },
    },
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    style: { width: 60 },
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  //delete
  const [products, setProducts] = useState(data);
  const handleCategory = async (id) => {
    const confirmation = window.confirm("Are you Sure?");
    if (confirmation) {
      const url = `https://api.dsmartuniforms.com/api/banner/${id}`;
      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          MySwal.fire("Good job!", "successfully deleted", "success");
          if (data.deletedCount === 1) {
            const remainItem = products.filter((item) => item._id !== id);
            setProducts(remainItem);
          }
        });
    }
  };
  return (
    <div className="wrapper">
      <div className="content-wrapper" style={{ background: "unset" }}>
        <section className="content customize_list">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body card_body_sm">
                <>
                  <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    columns={columns}
                    data={mainData || []}
                    pagination={pagination}
                    exportCSV
                  >
                    {(props) => (
                      <React.Fragment>
                        <BootstrapTable
                          bootstrap4
                          keyField="_id"
                          columns={columns}
                          data={mainData || []}
                          pagination={pagination}
                          {...props.baseProps}
                        />
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CustomVendorDemoList;
