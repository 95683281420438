import axios from "axios";
import React, { useState, useRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Add_School = () => {
  const [file, setFile] = useState(null); // Change from array to a single file
  const MySwal = withReactContent(Swal);
  const formRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data2 = {
      name: formData.get("name"),
      location: formData.get("location"),
      email: formData.get("email"),
      password: formData.get("password"),
    };

    try {
      let list = "";

      if (file) {
        const data = new FormData();
        data.append("file", file);
        data.append("upload_preset", "upload");
        const uploadRes = await axios.post(
          "https://api.cloudinary.com/v1_1/dtpvtjiry/image/upload",
          data
        );

        const { secure_url } = uploadRes.data;
        list = secure_url;
      }

      const product = {
        ...data2,
        photos: list,
      };

      await axios.post("https://api.dsmartuniforms.com/api/school", product);
      MySwal.fire("Good job!", "successfully added", "success");
      formRef.current.reset();
      setFile(null);
    } catch (err) {
      MySwal.fire("Something Error Found.", "warning");
    }
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper" style={{ background: "unset" }}>
        <div className="customize registration_div card">
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="row p-3">
              <div className="col-md-12 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  Name
                </label>

                <input
                  type="text"
                  className="main_form w-100"
                  name="name"
                  placeholder="School Name"
                />
              </div>
              <div className="col-md-12 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  Location
                </label>

                <input
                  type="text"
                  className="main_form w-100"
                  name="location"
                  placeholder="School Location"
                />
              </div>
              <div className="col-md-12 form_sub_stream">
                <label
                  htmlFor="managerEmail"
                  className="form-label profile_label3"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="main_form w-100"
                  id="managerEmail"
                  name="email"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="col-md-12 form_sub_stream">
                <label
                  htmlFor="managerPassword"
                  className="form-label profile_label3"
                >
                  Password
                </label>
                <input
                  type="password"
                  className="main_form w-100"
                  id="managerPassword"
                  name="password"
                  placeholder="Password"
                  required
                />
              </div>
              <div className="col-md-12 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  Image upload
                </label>

                <input
                  type="file"
                  className="main_form p-0"
                  name="img"
                  onChange={(e) => setFile(e.target.files[0])} // Set the single file
                  style={{ width: "300px" }}
                />
              </div>
            </div>

            <div className="d-flex justify-content-center my-5">
              <button
                type="submit"
                className="profile_btn"
                style={{ width: 175 }}
              >
                Add School
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Add_School;
