import axios from "axios";
import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TINY_MCE_EDITOR_INIT } from "../../utils/constants";

const BlogUpdate = ({ data, refetch }) => {
  const [formData, setFormData] = useState({
    name: data.name || "",
    desc: data.desc || "",
  });

  const [editorKey, setEditorKey] = useState(1);

  const MySwal = withReactContent(Swal);
  const formRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios.put(
        `https://api.dsmartuniforms.com/api/blogs/${data._id}`,
        formData
      );
      MySwal.fire("Good job!", "Successfully updated", "success");
      formRef.current.reset();
      setEditorKey((prevKey) => prevKey + 1);
      refetch();
      window.location.reload();
    } catch (err) {
      MySwal.fire("Something Error Found.", "warning");
    }
  };

  return (
    <div className="">
      <div
        className="modal fade "
        id={`update${data._id}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" style={{ maxWidth: "1000px" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title fs-4" id="staticBackdropLabel">
                Update Blog
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body w-100">
              <div className="wrapper">
                <div className="col-md-12 form_sub_stream">
                  <label
                    htmlFor="inputState"
                    className="form-label profile_label3"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="main_form w-100"
                    name="name"
                    placeholder="Blog Name"
                    onBlur={handleInputChange}
                    defaultValue={formData.name}
                  />
                </div>

                <div
                  className="content-wrapper ms-0"
                  style={{ background: "unset" }}
                >
                  <div className="registration_div card p-3">
                    <form ref={formRef} onSubmit={handleSubmit}>
                      <div className="col-md-12 form_sub_stream">
                        <Editor
                          apiKey="9i9siri6weyxjml0qbccbm35m7o5r42axcf3lv0mbr0k3pkl"
                          key={editorKey}
                          initialValue={formData.desc}
                          init={TINY_MCE_EDITOR_INIT}
                          value={formData.desc}
                          onEditorChange={(content) =>
                            setFormData({ ...formData, desc: content })
                          }
                        />
                      </div>

                      <div className="d-flex justify-content-center my-5">
                        <button
                          type="submit"
                          className="profile_btn"
                          style={{ width: 175 }}
                          data-bs-dismiss="modal"
                        >
                          Update Privacy
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogUpdate;
