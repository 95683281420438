import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";

import OrderStatusUpdate from "../../pages/edit/OrderStatusUpdate";
import { AiOutlineEye, AiOutlineFieldTime } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";

import { useQuery } from "react-query";

import { AuthContext } from "../../contexts/UserProvider";
import CustomOrderDemoUpdate from "../../pages/edit/CustomOrderDemoUpdate";
import CustomerOrderDemoUpdate from "../../pages/edit/CustomerOrderDemoUpdate";
import { Link } from "react-router-dom";

const CustomerVendorOrderList = ({ setAllBookings }) => {
  const MySwal = withReactContent(Swal);
  const { user } = useContext(AuthContext);

  const userId = user?._id;
  //sub stream

  const { data, error, refetch } = useQuery("customerAdvanceData", async () => {
    const response = await axios.get(
      `https://api.dsmartuniforms.com/api/advance/${userId}`,
      {
        mode: "cors",
      }
    );
    return response.data;
  });
  useEffect(() => {
    if (error) {
      console.error("Error fetching banner data:", error);
    }
  }, [error, data]);

  const columns = [
    {
      text: "No",
      formatter: (cellContent, row, index) => {
        return (
          <>
            {" "}
            <p>{index + 1}</p>
          </>
        );
      },
    },
    {
      text: "Name",
      formatter: (cellContent, row, index) => {
        return (
          <>
            {" "}
            <p>{row?.name}</p>
          </>
        );
      },
    },
    {
      dataField: "quantity",
      text: "Quantity",
    },
    {
      dataField: "details",
      text: "File Information",
      formatter: (cellContent, row) => {
        const fileDetails = row?.details?.map((detail) => (
          <div key={detail?._id}>
            <a
              href={`https://api.dsmartuniforms.com/public/uploads/${detail?.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {detail?.url}
            </a>
          </div>
        ));
        return <>{fileDetails}</>;
      },
    },
    {
      dataField: "email",
      text: "Email",
    },

    {
      dataField: "number",
      text: "Number",
    },

    {
      dataField: "address",
      text: "Address",
    },
    {
      dataField: "students",
      text: "Student",
    },
    {
      dataField: "ratio",
      text: "Class Ration",
    },
    {
      dataField: "topPart",
      text: "Top Part",
    },
    {
      dataField: "topFab",
      text: "Top Fabrications",
    },
    {
      dataField: "bottomPart",
      text: "Bottom part",
    },
    {
      dataField: "bottomFab",
      text: "Bottom Fabrications",
    },

    {
      text: "Demo Submit",
      formatter: (cellContent, row, index) => {
        return (
          <>
            <div className=" d-flex ">
              <div>
                <p className="fw-bold">Submit</p>
              </div>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target={`#demo${row._id}`}
                className="d-flex  bg-white p-0 w-full"
              >
                <BiSolidEdit style={{ width: "24px", height: "24px" }} />
              </button>
              {/* Modal Order Status Update */}
            </div>
            <div>
              <CustomerOrderDemoUpdate data={row} refetch={refetch} />
            </div>
          </>
        );
      },
    },
    {
      text: "Demo List",
      formatter: (cellContent, row) => {
        return (
          <>
            {" "}
            <div className="d-flex justify-content-center">
              <Link to={`/customer-vendor-demo-list/${row._id}`}>
                <AiOutlineEye
                  data-toggle="modal"
                  data-target={`#loginModal${row._id}`}
                  style={{ width: "24px", height: "24px" }}
                />
              </Link>
            </div>
          </>
        );
      },
    },
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    style: { width: 60 },
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  //delete
  const [products, setProducts] = useState(data);
  const handleDelete = async (id) => {
    const confirmation = window.confirm("Are you Sure?");
    if (confirmation) {
      const url = `https://api.dsmartuniforms.com/api/custom/${id}`;
      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          MySwal.fire("Good job!", "successfully deleted", "success");
          if (data.deletedCount === 1) {
            const remainItem = products.filter((item) => item._id !== id);
            setProducts(remainItem);
          }
        });
    }
  };
  return (
    <div className="wrapper">
      <div className="content-wrapper" style={{ background: "unset" }}>
        <section className="content customize_list">
          <div className="container-fluid">
            <div className=" d-flex justify-content-between gap-5 ">
              <h6 className=" ">Custom Order List</h6>
            </div>

            <div className="card">
              <div className="card-body card_body_sm">
                <>
                  <ToolkitProvider
                    bootstrap4
                    keyField="_id"
                    columns={columns}
                    data={data || []}
                    pagination={pagination}
                    exportCSV
                  >
                    {(props) => (
                      <React.Fragment>
                        <BootstrapTable
                          bootstrap4
                          keyField="_id"
                          columns={columns}
                          data={data || []}
                          pagination={pagination}
                          {...props.baseProps}
                        />
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </>
              </div>
            </div>

            {/* /.row (main row) */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      {/* Control Sidebar */}
    </div>
  );
};

export default CustomerVendorOrderList;
