import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { AuthContext } from "../../contexts/UserProvider";

const CustomOrderDemoUpdate = ({ data, refetch }) => {
  const { user } = useContext(AuthContext);
  const [files, setFiles] = useState("");
  const [userName, setUserName] = useState("");
  const [advance, setAdvance] = useState("");
  const MySwal = withReactContent(Swal);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      Object.values(files).forEach((file) => {
        formData.append("file", file);
        formData.append("advanceId", data._id);
        formData.append("userName", user?.name);
        formData.append("email", user?.email);
      });

      await axios.post(
        "https://api.dsmartuniforms.com/api/vendorDemo",
        formData
      );
      MySwal.fire("Good job!", "successfully added", "success");
    } catch (err) {
      MySwal.fire("Something Error Found.", "warning");
    }
  };

  return (
    <div className="container">
      <div
        className="modal fade"
        id={`demo${data._id}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Demo
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <form onSubmit={handleSubmit}>
                  {/* <div className="col-md-6 form_sub_stream ">
                    <label htmlFor="inputState" className="profile_label3">
                      Order No
                    </label>
                    <select
                      name="adminOrder"
                      id="inputState"
                      className="main_form w-100"
                      onChange={(e) => setAdvance(e.target.value)}
                    >
                      <option>Select Id</option>
                      <option value={data._id}>{data._id.slice(15)}</option>
                    </select>
                  </div> */}
                  {/* <div className="col-md-6 form_sub_stream">
                    <label
                      htmlFor="inputState"
                      className="form-label profile_label3 "
                    >
                      Name
                    </label>

                    <input
                      type="text"
                      className="main_form w-100"
                      name="userName"
                      placeholder="Name"
                      defaultValue={user?.name}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div> */}

                  <div className="col-md-12 mb-3">
                    <input
                      type="file"
                      className="main_form p-0"
                      name="img"
                      onChange={(e) => setFiles(e.target.files)}
                      multiple
                      style={{ width: "300px" }}
                    />
                  </div>
                  <div className="d-flex  ml-5">
                    <button type="submit" style={{ width: 220 }}>
                      Demo Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomOrderDemoUpdate;
