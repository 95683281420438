import React from "react";
import Project_list from "../components/Project/Project_list";

const Project = () => {
  return (
    <div>
      <Project_list />
    </div>
  );
};

export default Project;
