import "bootstrap-4-react";
import { Route, Routes } from "react-router-dom";
import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import Add_Category from "./pages/Add_Category";
import Category from "./pages/Category";
import SubCategory from "./pages/SubCategory";
import Property from "./pages/Product";
import Orders from "./pages/Orders";
import Add_product from "./pages/Add_product";
import Promo from "./pages/Promo";
import Add_Promo from "./pages/Add_Promo";
import Add_Project from "./pages/Add_Project";

import PrivateRoute from "./Routes/PrivateRoute/PrivateRoute";
import AdminRoute from "./Routes/AdminRoute/AdminRoute";

import Add_Manager from "./pages/Add_manager";
import Manager from "./pages/Manager";
import Add_Branch from "./pages/Add_Branch";
import Branch from "./pages/Branch";

import Issue from "./pages/issue";
import Admin_product from "./pages/Admin_product";
import AdminOrders from "./pages/AdminOrders";
import Review from "./pages/Review";
import Issues from "./pages/Issues";
import Admin_issue from "./pages/Admin_issue";
import Invoice from "./pages/details/Invoice";
import Add_Banner from "./pages/Add_Banner";
import Banner from "./pages/Banner";
import LeasePropertyList from "./components/LeaseProperty/LeasePropertyList";
import Partner_list from "./components/Manager/Partner_list";
import Partner_property_list from "./components/Product/Partner_product_list";

import ExtraCharge from "./pages/ExtraCharge/ExtraCharge";
import TransactionAdmin from "./pages/Transaction/TransactionAdmin";
import UserManage from "./pages/UserMange/UserManage";
import TransactionManager from "./pages/Transaction/TransactionManager";
import Add_Privacy from "./components/Pages/Add_Privacy";

import "./App.css";
import Pages from "./pages/Pages";
import Terms_list from "./components/Pages/Terms_list";
import Add_Terms from "./components/Pages/Add_Terms";
import Privacy_list from "./components/Pages/Privacy_list";
import Update_Terms from "./components/Pages/Update_Terms";
import Update_Privacy from "./components/Pages/Update_Privacy";
import Add_Sub_Category from "./pages/Add_Sub_Category";
import Add_Blog from "./pages/Add_Blog";
import Blog from "./pages/Blog";
import Vendor_product from "./pages/Vendor_product";
import CustomOrderList from "./components/Orders/Custom_order_list";
import Contact from "./pages/Contact";
import CustomVendorOrderList from "./components/Orders/CustomVendorOrderList";
import CustomDemoList from "./components/Orders/CustomDemoList";
import Vendor_request_list from "./components/Manager/Vendor_request_list";
import Add_Admin_Order from "./pages/Add_Admin_Order";
import CustomAdminOrderList from "./components/Orders/Custom_admin_order_list";
import CustomVendorDemoList from "./components/Orders/CustomVendorDemoList";
import CustomerVendorOrderList from "./components/Orders/CustomerVendorOrderList";
import CustomerDemoList from "./components/Orders/CustomerDemoList";
import CustomerVendorDemoList from "./components/Orders/CustomerVendorDemoList copy";
import Add_Client from "./pages/Add_Client";
import OurClient from "./pages/OurClient";
import Project from "./pages/Project";
import Add_Feedback from "./pages/Add_Feedback";
import Feedback from "./pages/FeedBack";
import Add_School from "./pages/Add_School";
import School from "./pages/School";
import StudentsList from "./components/School/StudentsList";
import SchoolDataList from "./components/School/SchoolDataList";
import Add_Faq from "./pages/Add_Faq";
import Faq from "./pages/Faq";

function App() {
  return (
    <div className="">
      <Routes>
        <Route element={<SignIn />}>
          <Route path="/signup" element={<SignIn />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
        </Route>
        <Route path="/" element={<AdminRoute />}>
          <Route path="/" element={<Home />} />

          <Route path="add_vendor" element={<Add_Manager />} />
          <Route path="vendor_list" element={<Manager />} />
          <Route path="partner_list" element={<Partner_list />} />
          <Route path="vendor_request_list" element={<Vendor_request_list />} />

          <Route path="add_category" element={<Add_Category />} />
          <Route path="category_list" element={<Category />} />
          <Route path="add_sub_category" element={<Add_Sub_Category />} />
          <Route path="sub_category_list" element={<SubCategory />} />
          <Route path="add_branch" element={<Add_Branch />} />
          <Route path="branch_list" element={<Branch />} />

          <Route path="add_product" element={<Add_product />} />
          <Route path="product_list_m" element={<Property />} />
          <Route path="product_list_p" element={<Partner_property_list />} />
          <Route path="product_list_all" element={<Admin_product />} />
          <Route path="product_list" element={<Vendor_product />} />
          <Route path="add_promo" element={<Add_Promo />} />
          <Route path="promo_list" element={<Promo />} />
          <Route path="add_banner" element={<Add_Banner />} />
          <Route path="banner_list" element={<Banner />} />
          <Route path="add_client" element={<Add_Client />} />
          <Route path="client_list" element={<OurClient />} />
          <Route path="add_blog" element={<Add_Blog />} />
          <Route path="blog_list" element={<Blog />} />

          <Route path="add_school" element={<Add_School />} />
          <Route path="school_list" element={<School />} />
          <Route path="student_list/:id" element={<StudentsList />} />
          <Route path="schoolList" element={<SchoolDataList />} />

          <Route path="add_admin_order" element={<Add_Admin_Order />} />
          <Route
            path="custom_admin_order_list"
            element={<CustomAdminOrderList />}
          />
          <Route path="orders_m" element={<Orders />} />
          <Route path="customOrders_a" element={<CustomOrderList />} />
          <Route path="customOrders" element={<CustomVendorOrderList />} />
          <Route path="customerOrders" element={<CustomerVendorOrderList />} />
          <Route path="/demo-list/:id" element={<CustomDemoList />} />
          <Route
            path="/customer-demo-list/:id"
            element={<CustomerDemoList />}
          />
          <Route
            path="/customer-vendor-demo-list/:id"
            element={<CustomerVendorDemoList />}
          />
          <Route
            path="/vendor-demo-list/:id"
            element={<CustomVendorDemoList />}
          />
          <Route path="contactlist" element={<Contact />} />
          <Route path="orders" element={<AdminOrders />} />
          <Route path="transaction" element={<TransactionAdmin />} />
          <Route path="transaction-m" element={<TransactionManager />} />
          <Route path="user-manage" element={<UserManage />} />

          <Route path="issue" element={<Issue />} />
          <Route path="issues_m" element={<Issues />} />
          <Route path="issues" element={<Admin_issue />} />
          <Route path="review" element={<Review />} />
          <Route path="leaseProperty" element={<LeasePropertyList />} />
          <Route path="extraCharge" element={<ExtraCharge />} />
          <Route path="invoice/:id" element={<Invoice />} />

          <Route path="add_project" element={<Add_Project />} />
          <Route path="project_list" element={<Project />} />

          <Route path="add_feedBack" element={<Add_Feedback />} />
          <Route path="feedBack_list" element={<Feedback />} />

          <Route path="pages" element={<Pages />} />
          <Route path="add_privacy" element={<Add_Privacy />} />
          <Route path="privacy_list" element={<Privacy_list />} />
          <Route path="add_faq" element={<Add_Faq />} />
          <Route path="faq" element={<Faq />} />
          <Route path="terms_list" element={<Terms_list />} />
          <Route path="update_terms/:id" element={<Update_Terms />} />
          <Route path="update_privacy/:id" element={<Update_Privacy />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
