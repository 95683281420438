import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { useQuery } from "react-query";

const AssignVendor = ({ data }) => {
  const { _id, vendors: userVendors } = data;
  const {
    data: vendor,
    error,
    refetch: vendorRefetch,
  } = useQuery("vendor", async () => {
    const response = await axios.get(
      "https://api.dsmartuniforms.com/api/users/vendor",
      {
        mode: "cors",
      }
    );
    return response.data;
  });
  const [user, setUser] = useState(data);
  const [selectedVendorIds, setSelectedVendorIds] = useState(userVendors || []);
  const MySwal = withReactContent(Swal);

  const handleCheckboxChange = (vendorId) => {
    setSelectedVendorIds((prevSelected) => {
      if (prevSelected.includes(vendorId)) {
        return prevSelected.filter((id) => id !== vendorId);
      } else {
        return [...prevSelected, vendorId];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedStatus = {
        ...user,
        vendors: selectedVendorIds, // Include the selected vendors
      };

      await axios.put(
        `https://api.dsmartuniforms.com/api/adminOrder/${_id}`,
        updatedStatus
      );

      MySwal.fire("Updated", "success");
    } catch (err) {
      console.error(err);
      MySwal.fire("Something went wrong.", "error");
    }
  };

  return (
    <div className="container">
      <div
        className="modal fade"
        id={`assign${data._id}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Assign Vendor
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div>
                      <div className="">
                        <div className="col-md-12 mb-3">
                          {vendor?.map((vendor) => (
                            <div key={vendor._id} className="form-check mb-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`vendorCheckbox_${vendor._id}`}
                                value={vendor._id}
                                checked={selectedVendorIds.includes(vendor._id)}
                                onChange={() =>
                                  handleCheckboxChange(vendor._id)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`vendorCheckbox_${vendor._id}`}
                              >
                                {vendor.name}
                              </label>
                            </div>
                          ))}
                          {/* <button onClick={assignVendor}>Assign Vendor</button> */}
                        </div>

                        <div className="d-flex justify-content-center ml-5">
                          <button type="submit" style={{ width: 220 }}>
                            Update Status
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignVendor;
