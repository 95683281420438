import React, { useState } from "react";
import "./Home.css";
import img from "../../img/new/style.png";
import AdminOrderList from "../Orders/AdminOrderList";
import { AuthContext } from "../../contexts/UserProvider";
import { useContext } from "react";
import axios from "axios";
import { useQuery } from "react-query";

import ManagerOrdersList from "../Orders/ManagerOrdersList";

const Homes = () => {
  const { user } = useContext(AuthContext);
  const [allBookings, setAllBookings] = useState([]);

  // Find Total Booking Amount Branch Or All Booking By Filtering
  let totalBookingAmount = 0;

  for (const item of allBookings) {
    totalBookingAmount += item.totalAmount;
  }
  // Find Total Booking Amount Branch Or All Booking By Filtering
  let totalReceiveAmount = 0;

  for (const item of allBookings) {
    totalReceiveAmount += item.totalReceiveTk;
  }

  let totalDueAmount = 0;

  for (const item of allBookings) {
    totalDueAmount += item.dueAmount;
  }
  const { data } = useQuery("schoolData", async () => {
    const response = await axios.get(
      "https://api.dsmartuniforms.com/api/school",
      {
        mode: "cors",
      }
    );
    return response.data;
  });
  const { data: ecommerceOrder } = useQuery("ecommerceOrderData", async () => {
    const response = await axios.get(
      "https://api.dsmartuniforms.com/api/order",
      {
        mode: "cors",
      }
    );
    return response.data;
  });
  const { data: totalGrandPrice } = useQuery("totalGrandPrice", async () => {
    const response = await axios.get(
      "https://api.dsmartuniforms.com/api/order/totalGrandPrice",
      {
        mode: "cors",
      }
    );
    return response.data;
  });
  const { data: customOrder } = useQuery("customOrder", async () => {
    const response = await axios.get(
      "https://api.dsmartuniforms.com/api/advance",
      {
        mode: "cors",
      }
    );
    return response.data;
  });
  const { data: users } = useQuery("users", async () => {
    const response = await axios.get(
      "https://api.dsmartuniforms.com/api/users",
      {
        mode: "cors",
      }
    );
    return response.data;
  });
  const totalVendor = users?.filter((item) => item?.role === "vendor");
  const { data: vendor } = useQuery("vendor", async () => {
    const response = await axios.get(
      "https://api.dsmartuniforms.com/api/vendor",
      {
        mode: "cors",
      }
    );
    return response.data;
  });
  const { data: adminOrder } = useQuery("adminOrder", async () => {
    const response = await axios.get(
      "https://api.dsmartuniforms.com/api/adminOrder",
      {
        mode: "cors",
      }
    );
    return response.data;
  });

  const pendingTotal = data?.schools?.filter(
    (item) => item.status === "pending"
  );
  const deliveryTotal = data?.schools?.filter(
    (item) => item.status === "delivery"
  );
  return (
    <>
      <div>
        <div>
          <div className="wrapper">
            {/* Content Wrapper. Contains page content */}
            <div
              className="content-wrapper h-0"
              style={{ background: "unset" }}
            >
              <h6 className="fs-3 text-center">Schools Measurements History</h6>
              <div className="row customize">
                <div className="col-md-3 home_card_m">
                  <div className="card_1">
                    <div className="d-flex p-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={img} alt="" className="img1" />
                      </div>
                      <div className="ms-3 text-white">
                        <p className="fs-5">Total Schools</p>
                        <p className="fw-bold">
                          {data?.schools?.length} Schools
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 home_card_m">
                  <div className="card_2">
                    <div className="d-flex p-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={img} alt="" className="img2" />
                      </div>
                      <div className="ms-3 text-white">
                        <p className="fs-5">Total Measurements</p>
                        <p className="fw-bold"> {data?.totalStudents}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card_3">
                    <div className="d-flex p-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={img} alt="" className="img3" />
                      </div>
                      <div className="ms-3 text-white">
                        <p>Total Pending Order</p>
                        <p className="fw-bold">{pendingTotal?.length}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card_4">
                    <div className="d-flex p-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={img} alt="" className="img3" />
                      </div>
                      <div className="ms-3 text-white">
                        <p>Total Delivery Order</p>
                        <p className="fw-bold"> {deliveryTotal?.length}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="fs-3 text-center">Ecommerce History</h6>
              <div className="row customize">
                <div className="col-md-3 home_card_m">
                  <div className="card_1">
                    <div className="d-flex p-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={img} alt="" className="img1" />
                      </div>
                      <div className="ms-3 text-white">
                        <p className="fs-5">Total Ecommerce Orders</p>
                        <p className="fw-bold">{ecommerceOrder?.length}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 home_card_m">
                  <div className="card_2">
                    <div className="d-flex p-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={img} alt="" className="img2" />
                      </div>
                      <div className="ms-3 text-white">
                        <p className="fs-5">Ecommerce Order Amount</p>
                        <p className="fw-bold">
                          ৳ {totalGrandPrice?.totalGrandPrice}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card_3">
                    <div className="d-flex p-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={img} alt="" className="img3" />
                      </div>
                      <div className="ms-3 text-white">
                        <p>Total Custom Orders</p>
                        <p className="fw-bold">{customOrder?.length}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card_4">
                    <div className="d-flex p-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={img} alt="" className="img3" />
                      </div>
                      <div className="ms-3 text-white">
                        <p>Total Admin Orders</p>
                        <p className="fw-bold"> {adminOrder?.length}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="fs-3 text-center">Users Details</h6>
              <div className="row customize">
                <div className="col-md-3 home_card_m">
                  <div className="card_1">
                    <div className="d-flex p-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={img} alt="" className="img1" />
                      </div>
                      <div className="ms-3 text-white">
                        <p className="fs-5">Total Users</p>
                        <p className="fw-bold">{users?.length}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card_3">
                    <div className="d-flex p-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={img} alt="" className="img3" />
                      </div>
                      <div className="ms-3 text-white">
                        <p>Total Vendor Requests</p>
                        <p className="fw-bold">{vendor?.length}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 home_card_m">
                  <div className="card_2">
                    <div className="d-flex p-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={img} alt="" className="img2" />
                      </div>
                      <div className="ms-3 text-white">
                        <p className="fs-5">Total Vendor</p>
                        <p className="fw-bold">{totalVendor?.length} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {user?.role === "manager" || user?.role === "partner" ? (
        <div className="mt-0">
          <ManagerOrdersList setAllBookings={setAllBookings} />
        </div>
      ) : (
        <div className="mt-0">
          <AdminOrderList setAllBookings={setAllBookings} />
        </div>
      )} */}
    </>
  );
};

export default Homes;
