import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import img from "../../img/college/Icon material-delete.png";

import "./Main_steam.css";
import axios from "axios";

const SubCategory = ({ data, refetch }) => {
  const { _id, name, photos } = data;
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState(data?.category?.name);
  const [user, setUser] = useState({ ...data, category: data?.category?._id });

  const [file, setFile] = useState(null);
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const [selectedDeletedPhotoIndex, setSelectedDeletedPhotoIndex] =
    useState(null);

  useEffect(() => {
    setPreviewPhoto(photos && photos.length > 0 ? photos : null);
  }, [photos]);
  const MySwal = withReactContent(Swal);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.dsmartuniforms.com/api/category"
        );
        setCategories(response.data);

        if (!user.categoryId && response.data.length > 0) {
          setUser((prevUser) => ({
            ...prevUser,
            categoryId: response.data[0]._id,
          }));
          setCategoryName(response.data[0].name);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [user.categoryId]);

  const handleCategoryChange = (event) => {
    const selectedCategoryId = event.target.value;
    const selectedCategory = categories.find(
      (category) => category._id === selectedCategoryId
    );

    setCategoryName(selectedCategory?.name || "");
    setUser((prevUser) => ({
      ...prevUser,
      categoryId: selectedCategoryId,
    }));
  };

  const handleOnBlur = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newInfo = { ...user };
    newInfo[field] = value;
    setUser(newInfo);
  };
  const handleDeleteSubCategoryPhoto = (photoIndex) => {
    setPreviewPhoto(null);
    setFile(null);
    setSelectedDeletedPhotoIndex(0);

    setUser({ ...user, photos: null });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newPost = {
      ...user,
    };
    try {
      let finalPhotos = photos;

      if (selectedDeletedPhotoIndex !== null) {
        finalPhotos = null;
      }

      if (file) {
        const data = new FormData();
        data.append("file", file[0]);
        data.append("upload_preset", "upload");
        const uploadRes = await axios.post(
          "https://api.cloudinary.com/v1_1/dtpvtjiry/image/upload",
          data
        );

        const { secure_url } = uploadRes.data;
        finalPhotos = secure_url;
      }

      const updatedProduct = {
        ...newPost,
        photos: finalPhotos || "",
      };

      await axios.put(
        `https://api.dsmartuniforms.com/api/subcategory/${_id}`,
        updatedProduct
      );
      MySwal.fire("Good job!", "successfully edited", "success");
      refetch();
    } catch (err) {
      MySwal.fire("Something Error Found.", "warning");
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div>
            <div className="card-body">
              <div className="col-md-12 form_sub_stream ">
                <label htmlFor="inputState" className="profile_label3">
                  Category
                </label>
                <select
                  name="category"
                  id="inputState"
                  className="main_form w-100"
                  onChange={handleCategoryChange}
                  value={user.categoryId || ""}
                >
                  <option>Select Category</option>
                  {categories.map((pd) => (
                    <option key={pd._id} value={pd._id}>
                      {pd.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3"
                >
                  SubCategory Name
                </label>
                <input
                  type="text"
                  className="main_form  w-100"
                  name="name"
                  onBlur={handleOnBlur}
                  defaultValue={name || ""}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3"
                >
                  SubCategory Picture
                </label>
                <input
                  type="file"
                  className="main_form w-100 p-0"
                  name="img"
                  onChange={(e) => {
                    setFile(e.target.files);
                    setPreviewPhoto(URL.createObjectURL(e.target.files));
                  }}
                />
              </div>
              {previewPhoto && (
                <div className="mt-2">
                  <p>Preview Photo:</p>
                  <div className="d-flex align-items-center">
                    <img
                      src={previewPhoto}
                      alt={`Preview Photo`}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        marginRight: "5px",
                        marginBottom: "15px",
                      }}
                    />
                    <img
                      src={img}
                      alt="remove category"
                      className="ms-3"
                      onClick={handleDeleteSubCategoryPhoto}
                      style={{ width: 20, cursor: "pointer" }}
                    />
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="profile_btn"
                  style={{ width: 220 }}
                >
                  Edit SubCategory
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SubCategory;
