import React from "react";
import Category_list from "../components/Category/Category_list";
import "bootstrap/dist/css/bootstrap.min.css";
const Category = () => {
  return (
    <div>
      <Category_list />
    </div>
  );
};

export default Category;
