import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

const Add_Admin_Order = () => {
  const [files, setFiles] = useState("");
  const MySwal = withReactContent(Swal);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      Object.values(files).forEach((file) => {
        formData.append("file", file);
      });

      await axios.post(
        "https://api.dsmartuniforms.com/api/adminOrder",
        formData
      );
      MySwal.fire("Good job!", "successfully added", "success");
    } catch (err) {
      MySwal.fire("Something Error Found.", "warning");
    }
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper " style={{ background: "unset" }}>
        <div className="customize registration_div card">
          <form onSubmit={handleSubmit}>
            <div className="row p-3 ">
              <div className="col-md-12 mb-3">
                <input
                  type="file"
                  className="main_form p-0"
                  name="img"
                  onChange={(e) => setFiles(e.target.files)}
                  multiple
                  style={{ width: "300px" }}
                />
              </div>
            </div>

            <div className="d-flex justify-content-center my-5">
              <button
                type="submit"
                className="profile_btn"
                style={{ width: 175 }}
              >
                Add Admin Order
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Add_Admin_Order;
