import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthContext } from "../contexts/UserProvider";
import { useContext } from "react";
const Add_product = () => {
  const { user } = useContext(AuthContext);

  const [files, setFiles] = useState("");
  const MySwal = withReactContent(Swal);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);

  const handleCategoryChange = (event) => {
    const selectedCategoryId = event.target.value;
    const selectedCategory = categories.find(
      (category) => category._id === selectedCategoryId
    );
    setCategoryName(selectedCategory?.name || "");
    setSelectedCategory(selectedCategoryId);
  };
  const handleChangeInput = (index, event) => {
    const { value } = event.target;
    const list = [...sizes];
    list[index] = value;
    setSizes(list);
  };

  const handleAddInput = () => {
    setSizes([...sizes, ""]);
  };
  const handleRemoveInput = (index) => {
    const list = [...sizes];
    list.splice(index, 1);
    setSizes(list);
  };
  //colors
  const handleChangeInputColor = (index, event) => {
    const { value } = event.target;
    const list = [...colors];
    list[index] = value;
    setColors(list);
  };

  const handleAddInputColor = () => {
    setColors([...colors, ""]);
  };

  const handleRemoveInputColor = (index) => {
    const list = [...colors];
    list.splice(index, 1);
    setColors(list);
  };

  const filteredSubcategories = subcategories?.filter(
    (subcategory) => subcategory?.category?._id == selectedCategory
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.dsmartuniforms.com/api/category"
        );
        setCategories(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.dsmartuniforms.com/api/subcategory"
        );
        setSubcategories(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const data2 = {
      name: formData.get("name"),
      desc: formData.get("desc"),
      fulldesc: formData.get("fulldesc"),
      categoryId: formData.get("category"),
      subcategoryId: formData.get("subcategory"),
      discountPrice: formData.get("discountPrice"),
      latest: formData.get("latest"),
      feature: formData.get("feature"),
      flashsale: formData.get("flashsale"),
      price: formData.get("price"),
      latest: formData.get("latest"),
      discountType: formData.get("discountType"),
    };

    try {
      const list = await Promise.all(
        Object.values(files).map(async (file) => {
          const data = new FormData();
          data.append("file", file);
          data.append("upload_preset", "upload");
          const uploadRes = await axios.post(
            "https://api.cloudinary.com/v1_1/dtpvtjiry/image/upload",
            data
          );

          const { secure_url } = uploadRes.data;
          return secure_url;
        })
      );

      const product = {
        ...data2,
        photos: list,
        userInfo: user,
        colors,
        sizes,
      };

      await axios.post("https://api.dsmartuniforms.com/api/product", product);
      MySwal.fire("Good job!", "successfully added", "success");
      // formRef.current.reset();
    } catch (err) {
      console.log(err);
      MySwal.fire(err?.response?.data?.message);
    }
  };
  return (
    <div className="wrapper">
      <div className="content-wrapper" style={{ background: "unset" }}>
        <div className="customize registration_div card">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-4 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  Product Name
                </label>
                <input
                  type="text"
                  className="main_form w-100"
                  name="name"
                  placeholder="Product Name"
                  required
                />
              </div>
              <div className="col-md-4 form_sub_stream ">
                <label htmlFor="inputState" className="profile_label3">
                  Product Category
                </label>
                <select
                  name="category"
                  id="inputState"
                  className="main_form w-100"
                  onChange={handleCategoryChange}
                >
                  <option>Select Category</option>
                  {categories.map((pd) => (
                    <option key={pd._id} value={pd._id}>
                      {pd.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-4 form_sub_stream ">
                <label htmlFor="inputState" className="profile_label3">
                  Sub Category
                </label>
                <select
                  name="subcategory"
                  id="inputState"
                  className="main_form w-100"
                >
                  <option value="">Select Sub Category</option>
                  {filteredSubcategories?.map((subcategory) => (
                    <option key={subcategory._id} value={subcategory._id}>
                      {subcategory.name}
                    </option>
                  ))}
                </select>
              </div>

              <h2 className="profile_label3 profile_bg">Details</h2>
              <div className="col-md-6 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  Full Description
                </label>
                <input
                  type="text"
                  className="main_form w-100"
                  name="fulldesc"
                  placeholder=" Full Description"
                  required
                />
              </div>
              <div className="col-md-6 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  Short Description
                </label>
                <input
                  type="text"
                  className="main_form w-100"
                  name="desc"
                  placeholder="Short Description"
                  required
                />
              </div>
              {/* <div className="col-md-3 form_sub_stream">
                <label htmlFor="inputState" className="profile_label3">
                  Size
                </label>
                <select
                  name="size"
                  id="size"
                  className="main_form w-100"
                  required
                >
                  <option value="sm">Sm</option>
                  <option value="md">Md</option>
                  <option value="xl">XL</option>
                </select>
              </div> */}
              <div
                className="col-md-5 form_sub_stream p-3 m-1"
                style={{ border: "1px solid red" }}
              >
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleAddInput}
                  >
                    Add Size<i class="fa-solid fa-plus"></i>
                  </button>
                </div>

                {sizes.map((size, index) => (
                  <div className="col-md-12 mt-2" key={index}>
                    <label
                      htmlFor={`size-${index}`}
                      className="profile_label3 mr-2"
                    >
                      Size {index + 1}
                    </label>
                    <div className="d-flex">
                      <input
                        type="text"
                        id={`size-${index}`}
                        name="size"
                        className="main_form w-50 mr-2"
                        value={size}
                        onChange={(e) => handleChangeInput(index, e)}
                        placeholder="Size"
                        required
                      />

                      <button
                        type="button"
                        className="btn btn-danger ml-2"
                        onClick={() => handleRemoveInput(index)}
                      >
                        <i class="fa-solid fa-minus"></i>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="col-md-5 form_sub_stream p-3 m-1"
                style={{ border: "1px solid red" }}
              >
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleAddInputColor}
                  >
                    Add Color<i class="fa-solid fa-plus"></i>
                  </button>
                </div>

                {colors.map((color, index) => (
                  <div className="col-md-12 mt-2" key={index}>
                    <label
                      htmlFor={`color-${index}`}
                      className="profile_label3 mr-2"
                    >
                      Color {index + 1}
                    </label>
                    <div className="d-flex">
                      <input
                        type="text"
                        id={`color-${index}`}
                        name="color"
                        className="main_form w-50 mr-2"
                        value={color}
                        onChange={(e) => handleChangeInputColor(index, e)}
                        placeholder="Color"
                        required
                      />

                      <button
                        type="button"
                        className="btn btn-danger ml-2"
                        onClick={() => handleRemoveInputColor(index)}
                      >
                        <i class="fa-solid fa-minus"></i>
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-md-3 form_sub_stream">
                <label htmlFor="inputState" className="profile_label3">
                  FlashSale
                </label>
                <select
                  name="flashsale"
                  id="flashsale"
                  className="main_form w-100"
                  required
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="col-md-3 form_sub_stream">
                <label htmlFor="inputState" className="profile_label3">
                  Latest
                </label>
                <select
                  name="latest"
                  id="latest"
                  className="main_form w-100"
                  required
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="col-md-3 form_sub_stream">
                <label htmlFor="inputState" className="profile_label3">
                  Feature
                </label>
                <select
                  name="feature"
                  id="feature"
                  className="main_form w-100"
                  required
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="col-md-3 form_sub_stream">
                <label htmlFor="inputState" className="profile_label3">
                  Stock
                </label>
                <select
                  name="stock"
                  id="latest"
                  className="main_form w-100"
                  required
                >
                  <option value="yes">Available</option>
                  <option value="no">Out Of Stock</option>
                </select>
              </div>
              <h2 className="profile_label3 profile_bg mt-5 mb-4">Price</h2>

              <div className="col-md-4 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  Regular Price
                </label>
                <input
                  type="text"
                  className="main_form w-100"
                  name="price"
                  placeholder="Regular Price"
                  required
                />
              </div>
              <div className="col-md-4 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  Discount Price
                </label>
                <input
                  type="text"
                  className="main_form w-100"
                  name="discountPrice"
                  placeholder="Discount Price"
                  required
                />
              </div>
              <div className="col-md-4 form_sub_stream">
                <label htmlFor="inputState" className="profile_label3">
                  Discount Type
                </label>
                <select
                  name="discountType"
                  id="discountType"
                  className="main_form w-100"
                  required
                >
                  <option value="winter">Winter</option>
                  <option value="summer">Summer</option>
                  <option value="regular">Regular</option>
                  <option value="no">No Offer</option>
                </select>
              </div>

              {categoryName === "Apartment" && (
                <>
                  <h2 className="profile_label3 profile_bg mt-5 mb-4">
                    Rent Policy
                  </h2>

                  <h2 className="profile_label3 profile_bg">Details</h2>
                </>
              )}
              <div className="col-md-12 form_sub_stream">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3 "
                >
                  Image
                </label>
                <input
                  type="file"
                  id="file"
                  className="main_form w-100 p-0"
                  name="photos"
                  onChange={(e) => setFiles(e.target.files)}
                  multiple
                  required
                />
              </div>
            </div>

            <div className="d-flex justify-content-center my-5">
              <button
                type="submit"
                className="profile_btn"
                style={{ width: 175 }}
                onSubmit={handleSubmit}
              >
                Add Product
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Add_product;
