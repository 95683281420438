import React from "react";
import Feedback_list from "../components/FeedBack/FeedBack_list";

const Feedback = () => {
  return (
    <div>
      <Feedback_list />
    </div>
  );
};

export default Feedback;
