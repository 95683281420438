import React from "react";
import Client_list from "../components/OurClient/OurClient";

const OurClient = () => {
  return (
    <div>
      <Client_list />
    </div>
  );
};

export default OurClient;
