import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import img from "../../img/college/Icon material-delete.png";

import "./Main_steam.css";
import axios from "axios";

const Category = ({ data, refetch }) => {
  const { _id, name, photos } = data;
  const [user, setUser] = useState(data);
  const [file, setFile] = useState(null);
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const [selectedDeletedPhotoIndex, setSelectedDeletedPhotoIndex] =
    useState(null);

  useEffect(() => {
    setPreviewPhoto(photos && photos.length > 0 ? photos : null);
  }, [photos]);

  const MySwal = withReactContent(Swal);

  const handleOnBlur = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newInfo = { ...user };
    newInfo[field] = value;
    setUser(newInfo);
  };
  const handleDeleteCategoryPhoto = () => {
    setPreviewPhoto(null);
    setFile(null);
    setSelectedDeletedPhotoIndex(0);
    setUser({ ...user, photos: null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newPost = {
      ...user,
    };

    try {
      let finalPhotos = photos;

      if (selectedDeletedPhotoIndex !== null) {
        finalPhotos = null;
      }

      if (file) {
        const data = new FormData();
        data.append("file", file[0]);
        data.append("upload_preset", "upload");
        const uploadRes = await axios.post(
          "https://api.cloudinary.com/v1_1/dtpvtjiry/image/upload",
          data
        );

        const { secure_url } = uploadRes.data;
        finalPhotos = secure_url;
      }

      const updatedProduct = {
        ...newPost,
        photos: finalPhotos || "",
      };

      await axios.put(
        `https://api.dsmartuniforms.com/api/category/${_id}`,
        updatedProduct
      );
      MySwal.fire("Good job!", "Successfully edited", "success");
      refetch();
    } catch (err) {
      MySwal.fire("Something Error Found.", "warning");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div>
            <div className="card-body">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3"
                >
                  Category Name
                </label>
                <input
                  type="text"
                  className="main_form w-100"
                  name="name"
                  onBlur={handleOnBlur}
                  defaultValue={name || ""}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="inputState"
                  className="form-label profile_label3"
                >
                  Category Picture
                </label>
                <input
                  type="file"
                  className="main_form w-100 p-0"
                  name="img"
                  onChange={(e) => {
                    setFile(e.target.files);
                    setPreviewPhoto(URL.createObjectURL(e.target.files));
                  }}
                />
                {previewPhoto && (
                  <div className="mt-2">
                    <p>Preview Photo:</p>
                    <div className="d-flex align-items-center">
                      <img
                        src={previewPhoto}
                        alt={`Preview Photo`}
                        style={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                          marginRight: "5px",
                          marginBottom: "15px",
                        }}
                      />
                      <img
                        src={img}
                        alt="remove category"
                        className="ms-3"
                        onClick={handleDeleteCategoryPhoto}
                        style={{ width: 20, cursor: "pointer" }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="profile_btn"
                  style={{ width: 220 }}
                >
                  Edit Category
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Category;
