import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Admin_product_list from "../components/Product/Admin_product_list";
const Admin_product = () => {
  return (
    <div>
      <Admin_product_list />
    </div>
  );
};

export default Admin_product;
